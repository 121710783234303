
const AllAppRoutes = {
    dashboard: "/dashboard",
    all_classes: "/classes/all",
    add_classes: "/classes/add",
    all_students: "/students/all",
    all_students: "/students/all",
    add_students: "/students/add",
    all_subjects: "/subjects/all",
    add_subjects: "/subjects/add",
    all_teachers: "/teachers/all",
    add_teachers: "/teachers/add",
    all_quizzes: "/quizzes/all",
    add_quizzes: "/quizzes/add",
    all_notes: "/notes/all",
    add_notes: "/notes/add",
    all_terms: "/terms/all",
    add_terms: "/terms/add"
}

export default AllAppRoutes;