import React, { useState, useEffect } from "react";

import { Input, Spin, Table, notification, Select, Divider } from "antd";
import { Upload, message, Modal } from 'antd';
import * as yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { _fake_get_call, _fetch_quiz_data, _save_quiz_data } from "../utils/axios-routes";

import { DateTime } from "luxon";
import { _fetch_class_data, _fetch_subjects_data, _save_subjects_data, _fetch_single_subject_data } from "../utils/axios-routes";
import { Link, useNavigate } from "react-router-dom";


import _1 from "../assets/images/_2.jpg";
import Sidenav from "../components/sidenav";
import TopBar from "../components/topbar";

const Classes = () => {

    const antIcon = (<LoadingOutlined style={{ fontSize: 24 }} spin />);
    const Navigate = useNavigate();

    const formValidator = yup.object().shape({
        // className: yup.string().required('Please enter class name')
    })
    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(formValidator)
    });
    const { fields: sectionFields, append: sectionAppend, remove: sectionRemove } = useFieldArray({
        control,
        name: 'question'
    });

    const { fields: sectionOptionsFields, append: sectionOptionAppend, remove: sectionOptionRemove } = useFieldArray({
        // control: controlOptionField,
        control,
        name: 'questionOptions'
    });

    const [displayList, setDisplayList] = useState(true);
    const [savingData, setSavingData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();

    const [subjectData, setSubjectData] = useState([]);
    const [classData, setClassData] = useState([]);
    const [fetchingData, setFetchingData] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [selectedClasses, setSelectedClasses] = useState([]);
    const [quizData, setQuizData] = useState([]);

    const openNotification = err => {
        notification.open({
            description: err
        });
    }

    useEffect(() => {
        if (localStorage.getItem('newDataSaved')) {
            openNotification('Data saved successfully.');
            localStorage.removeItem('newDataSaved');
        };
        let getAllSubjectData = async () => {
            try {
                let fetchSubjectData = await _fetch_subjects_data();
                if (fetchSubjectData.data.statusMessage === "success") {
                    setFetchingData(false);
                    let subjectArray = [];
                    fetchSubjectData.data.message.forEach(subject => {
                        let newSubjectObj = {
                            subjectName: subject.subjectName,
                            class: [subject.class.className],
                            dateAdded: subject.createdAt
                        }
                        let subjectWatch = false;
                        subjectArray.forEach((subject, index) => {
                            if (subject.subjectName === newSubjectObj.subjectName) {
                                subjectWatch = true;
                                subjectArray[index].class.push(newSubjectObj.class[0]);
                                subjectArray[index].dateAdded = newSubjectObj.dateAdded;
                            }
                        })
                        if (!subjectWatch) {
                            subjectArray.push(newSubjectObj);
                        }
                    })
                    setSubjectData(subjectArray);
                }
                setLoadingData(false)
            } catch (err) {
                openNotification('An error occurred while fetching subject data. Please reload to try again.');
                setLoadingData(false)
            }
        }
        let getAllQuizData = async () => {
            try {
                let fetchQuizData = await _fetch_quiz_data();
                if (fetchQuizData.data.statusMessage === "success") {
                    setFetchingData(false);
                    setQuizData(fetchQuizData.data.message);
                }
                setLoadingData(false)
            } catch (err) {
                openNotification('An error occurred while fetching quiz data. Please reload to try again.');
                setLoadingData(false)
            }
        }
        let getAllClassesData = async () => {
            try {
                let fetchClassData = await _fetch_class_data();
                if (fetchClassData.data.statusMessage === "success") {
                    setFetchingData(false);
                    setClassData(fetchClassData.data.message);
                }
                setLoadingData(false)
            } catch (err) {
                openNotification('An error occurred while fetching subject data. Please reload to try again.');
                setLoadingData(false)
            }
        }
        // getAllSubjectData();
        getAllQuizData();
        getAllClassesData();
    }, [])

    const columns = [
        {
            title: '',
            dataIndex: 'id',
        },
        {
            title: 'Quiz Title',
            className: 'column-money',
            dataIndex: 'quizTitle',
        },
        {
            title: 'Quiz Questions',
            className: 'column-money',
            dataIndex: 'quizQuestions',
        },
        {
            title: 'Display',
            className: 'column-money',
            dataIndex: 'display',
        },
        {
            title: 'Class',
            className: 'column-money',
            dataIndex: 'class',
        },
        {
            title: 'Date Added',
            dataIndex: 'dateAdded',
        },
        {
            title: '',
            dataIndex: 'editAction',
        },
        {
            title: '',
            dataIndex: 'deleteAction',
        },
    ];
    const data = quizData.map((quiz, index) => {
        return {
            key: index,
            id: index + 1,
            quizTitle: quiz.quizTitle,
            quizQuestions: quiz.quizQuestions.length,
            display: quiz.display ? 'true' : 'false',
            class: quiz.subject.subjectName,
            dateAdded: DateTime.fromISO(quiz.createdAt).toFormat('MMMM dd, yyyy'),
            editAction: <button onClick={() => setIsModalOpen(true)} className="btn-blue">Edit</button>,
            deleteAction: <button onClick={() => setIsModalOpen(true)} className="btn-red">Delete</button>
        }
    });

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    const getSubjectsData = async e => {
        try {
            let fetchSubjectData = await _fetch_single_subject_data(e);
            if (fetchSubjectData.data.statusMessage === "success") {
                setSubjectData(fetchSubjectData.data.message);
                setValue('studentClass', '');
            }
        } catch (err) {
            console.log(err)
        }
    }

    const saveQuiz = async e => {
        setErrorMessage('');
        const { subjectName } = e;
        setSavingData(true);
        try {
            let saveNewQuiz = await _save_quiz_data(e);
            if (saveNewQuiz.data.statusMessage === "success") {
                setSavingData(false);
                localStorage.setItem('newDataSaved', true);
                Navigate(0);
            } else {
                let errorMessage = saveNewQuiz.data.summary === "quiz name already saved. Please check and try again" ?
                    saveNewQuiz.data.summary : "An error occurred while trying to save quiz. Please try again.";
                setSavingData(false);
                setErrorMessage(errorMessage);
                openNotification(errorMessage);
            }
        } catch (err) {
            console.log(err)
            setSavingData(false);
            setErrorMessage('An error occurred while trying to save quiz. Please try again.');
            openNotification('An error occurred while trying to save quiz. Please try again.');
        }
    }


    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );


    return (
        <Spin indicator={antIcon} spinning={loadingData}>
            <div>
                <div>
                    <Sidenav />
                </div>
                <div className="main-display">
                    <div>
                        <TopBar />
                    </div>
                    <div>
                        <div className="main-display-content">
                            <div>
                                <div className="grid-flex page-header">
                                    <h3>Quizzes</h3>
                                    <div>
                                        {
                                            displayList ?
                                                <button
                                                    onClick={() => setDisplayList(false)}
                                                    className="btn-blue">Add New Quiz</button>
                                                :
                                                <button
                                                    onClick={() => setDisplayList(true)}
                                                    className="btn-blue">See All Quizzes</button>
                                        }
                                    </div>
                                </div>
                                <div className="">
                                    <div>
                                        <div className="content-box mb-4">
                                            {
                                                displayList ?
                                                    <React.Fragment>
                                                        <div className="content-box-header">
                                                            <div className="grid-flex">
                                                                <h3>Recently Added Quizzes</h3>
                                                            </div>
                                                        </div>
                                                        <div className="content-box-body">
                                                            <div>
                                                                <Table
                                                                    columns={columns}
                                                                    dataSource={data}
                                                                    bordered
                                                                    title={null}
                                                                    footer={null}
                                                                />
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <div className="content-box-header">
                                                            <div className="grid-flex">
                                                                <h3>Add New Quiz</h3>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="width-60">
                                                                <form onSubmit={handleSubmit(saveQuiz)}>
                                                                    <div className="form-group">
                                                                        <label htmlFor="quizTitle">Quiz title</label>
                                                                        <Controller control={control} defaultValue="" name="quizTitle"
                                                                            render={({ field }) => (
                                                                                <Input
                                                                                    style={{ height: '5rem' }} {...field}
                                                                                    name="quizTitle" id="quizTitle" type="text" />
                                                                            )} />
                                                                        {errors.quizTitle && <p className="error-message">{errors.quizTitle.message}</p>}
                                                                    </div>
                                                                    <div className="form-flex-2">
                                                                        <div className="form-group">
                                                                            <label htmlFor="studentClass">Class</label>
                                                                            <Controller control={control} defaultValue="" name="studentClass"
                                                                                render={() => (
                                                                                    <Select
                                                                                        onChange={e => getSubjectsData(e)}
                                                                                        style={{ height: '5rem', width: '100%' }}
                                                                                        name="studentClass" id="studentClass">
                                                                                        {
                                                                                            classData.map((userClass, index) => (
                                                                                                <Select.Option
                                                                                                    value={userClass.id}
                                                                                                    key={index}>{userClass.className}</Select.Option>
                                                                                            ))
                                                                                        }
                                                                                    </Select>
                                                                                )} />
                                                                            {errors.studentClass && <p className="error-message">{errors.studentClass.message}</p>}
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="studentClass">Subject</label>
                                                                            <Controller control={control} defaultValue="" name="studentClass"
                                                                                render={({ field }) => (
                                                                                    <Select style={{ height: '5rem', width: '100%' }} {...field}
                                                                                        name="studentClass" id="studentClass">
                                                                                        {
                                                                                            subjectData.map((userClass, index) => (
                                                                                                <Select.Option
                                                                                                    value={userClass.id}
                                                                                                    key={index}>{userClass.subjectName}</Select.Option>
                                                                                            ))
                                                                                        }
                                                                                    </Select>
                                                                                )} />
                                                                            {errors.studentClass && <p className="error-message">{errors.studentClass.message}</p>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="className">Notes</label>
                                                                        <div className="form-group">
                                                                            <button type="button" className="addSection btn-default" onClick={() => sectionAppend({})}>Add Question</button>
                                                                            <hr /><br />
                                                                            {sectionFields.map(({ id }, index) => {
                                                                                return (
                                                                                    <div className="sect" key={id}>
                                                                                        <button onClick={() => sectionRemove(index)} className="removeSection">
                                                                                            <DeleteOutlined />
                                                                                        </button>
                                                                                        <div className={`section${index}`}>
                                                                                            <Upload
                                                                                                name="avatar"
                                                                                                listType="picture-card"
                                                                                                className="avatar-uploader"
                                                                                                showUploadList={false}
                                                                                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                                                                beforeUpload={beforeUpload}
                                                                                                onChange={handleChange}
                                                                                            >
                                                                                                {imageUrl ? (
                                                                                                    <img
                                                                                                        src={imageUrl}
                                                                                                        alt="avatar"
                                                                                                        style={{
                                                                                                            width: '100%',
                                                                                                        }}
                                                                                                    />
                                                                                                ) : (
                                                                                                    uploadButton
                                                                                                )}
                                                                                            </Upload>
                                                                                            <div className="form-group">
                                                                                                <label htmlFor="question">Question {index + 1}</label>
                                                                                                <Controller defaultValue={""} name={`faqs${index + 1}.question`} control={control}
                                                                                                    id={`faqs${index + 1}.question`} render={({ field }) => (
                                                                                                        <Input {...field} style={{ height: '5rem' }} />
                                                                                                    )} />
                                                                                            </div>
                                                                                            <div className="form-group">
                                                                                                <label htmlFor="correct">Answer</label>
                                                                                                <Controller defaultValue={""} name={`faqs${index + 1}.correct`} control={control}
                                                                                                    id={`faqs${index + 1}.correct`} render={({ field }) => (
                                                                                                        <Input {...field} id="correct" style={{ height: '5rem' }} />
                                                                                                    )} />
                                                                                            </div>
                                                                                            <div>
                                                                                                <div className="form-flex-2">
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="answers">Option</label>
                                                                                                        <Controller defaultValue={""} name={`faqs${index + 1}.option1`} control={control}
                                                                                                            id={`faqs${index + 1}.option1`} render={({ field }) => (
                                                                                                                <Input {...field} id="answers" style={{ height: '5rem' }} />
                                                                                                            )} />
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="answers">Option</label>
                                                                                                        <Controller defaultValue={""} name={`faqs${index + 1}.option2`} control={control}
                                                                                                            id={`faqs${index + 1}.option2`} render={({ field }) => (
                                                                                                                <Input {...field} id="answers" style={{ height: '5rem' }} />
                                                                                                            )} />
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="answers">Option</label>
                                                                                                        <Controller defaultValue={""} name={`faqs${index + 1}.option3`} control={control}
                                                                                                            id={`faqs${index + 1}.option3`} render={({ field }) => (
                                                                                                                <Input {...field} id="answers" style={{ height: '5rem' }} />
                                                                                                            )} />
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="answers">Option</label>
                                                                                                        <Controller defaultValue={""} name={`faqs${index + 1}.option4`} control={control}
                                                                                                            id={`faqs${index + 1}.option4`} render={({ field }) => (
                                                                                                                <Input {...field} id="answers" style={{ height: '5rem' }} />
                                                                                                            )} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <button onClick={()=> inputAppend({})}>Add</button> */}

                                                                                            {/*
            <Options nestIndex={index + 1} control={controlExtraInfo} register={registerExtraInfo} /> */}

                                                                                        </div>
                                                                                        <Divider />
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        !savingData ?
                                                                            <button className="btn-blue">Save Note</button>
                                                                            :
                                                                            <button className="btn-blue" disabled><LoadingOutlined style={{ fontSize: '2rem' }} /></button>
                                                                    }
                                                                </form>
                                                                {/* <img src={_1} alt="" /> */}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="delete-button" title={null} open={isModalOpen} footer={null}>
                    <h3>Are you sure you want to delete this quiz? This action can not be reversed!</h3>
                    <div className="grid-flex">
                        <div></div>
                        <div className="grid-button">
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-default">Cancel</button>
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-red">Delete</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </Spin>
    )
}

export default Classes;