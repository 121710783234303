import React, { useState, useEffect } from "react";

import { Input, Spin, Table, notification, Modal, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as yup from 'yup';
import { DateTime } from "luxon";
import { Controller, useForm } from "react-hook-form";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { yupResolver } from '@hookform/resolvers/yup';
import { _fetch_class_data, _fetch_notes_data, _fetch_single_subject_data, _save_notes_data, _fetch_term_data } from "../utils/axios-routes";
import { Link, useNavigate } from "react-router-dom";


import _1 from "../assets/images/_2.jpg";
import Sidenav from "../components/sidenav";
import TopBar from "../components/topbar";

const Classes = () => {

    const formValidator = yup.object().shape({
    })
    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(formValidator)
    });

    const Navigate = useNavigate();
    const antIcon = (<LoadingOutlined style={{ fontSize: 24 }} spin />);

    const [displayList, setDisplayList] = useState(true);
    const [savingData, setSavingData] = useState(false);
    const [classData, setClassData] = useState([]);
    const [noteData, setNoteData] = useState([]);
    const [fetchingData, setFetchingData] = useState([]);
    const [loadingData, setLoadingData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [subjectData, setSubjectData] = useState([]);
    const [noteBody, setNoteBody] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [termData, setTermData] = useState([]);

    const openNotification = err => {
        notification.open({
            description: err
        });
    }

    useEffect(() => {
        if (localStorage.getItem('newDataSaved')) {
            openNotification('Data saved successfully.');
            localStorage.removeItem('newDataSaved');
        };
        let getAllNotesData = async () => {
            try {
                let fetchNoteData = await _fetch_notes_data();
                if (fetchNoteData.data.statusMessage === "success") {
                    setFetchingData(false);
                    setNoteData(fetchNoteData.data.message);
                }
                setLoadingData(false)
            } catch (err) {
                setLoadingData(false)
            }
        }
        let getAllClassesData = async () => {
            try {
                let fetchClassData = await _fetch_class_data();
                if (fetchClassData.data.statusMessage === "success") {
                    setFetchingData(false);
                    setClassData(fetchClassData.data.message);
                }
                setLoadingData(false)
            } catch (err) {
                setLoadingData(false)
            }
        }
        let getAllTermData = async () => {
            try {
                let fetchTermData = await _fetch_term_data();
                if (fetchTermData.data.statusMessage === "success") {
                    setTermData(fetchTermData.data.message);
                }
            } catch (err) {
                openNotification('An error occurred while fetching Term data. Please reload to try again.');
            }
        }
        getAllClassesData();
        getAllNotesData();
        getAllTermData();
    }, [])

    const getSubjectsData = async e => {
        try {
            let fetchSubjectData = await _fetch_single_subject_data(e);
            if (fetchSubjectData.data.statusMessage === "success") {
                setSubjectData(fetchSubjectData.data.message);
                setValue('studentClass', '');
            }
        } catch (err) {
            console.log(err)
        }
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'name',
        },
        {
            title: 'Subject',
            className: 'column-money',
            dataIndex: 'subject',
        },
        {
            title: 'Class',
            dataIndex: 'class',
        },
        {
            title: 'Created',
            dataIndex: 'dateAdded',
        },
        {
            title: '',
            dataIndex: 'editAction',
        }, {
            title: '',
            dataIndex: 'deleteAction',
        },
    ];
    const data = noteData.map((note, index) => {
        return (
            {
                key: index,
                name: note.noteTitle,
                subject: note?.subject?.subjectName,
                class: note?.subject?.class?.className,
                dateAdded: DateTime.fromISO(note.createdAt).toFormat('MMMM dd, yyyy'),
                editAction: <button onClick={() => setIsModalOpen(true)} className="btn-blue">Edit</button>,
                deleteAction: <button onClick={() => setIsModalOpen(true)} className="btn-red">Delete</button>
            }
        )
    });

    const saveSubject = async e => {
        setErrorMessage('');
        const { noteTitle, studentClass, termTitle } = e;
        let newStudentData = {
            noteTitle, noteBody, subjectId: studentClass, termId: termTitle
        };
        setSavingData(true);
        try {
            let saveNewNote = await _save_notes_data(newStudentData);
            console.log(saveNewNote);
            if (saveNewNote.data.statusMessage === "success") {
                setSavingData(false);
                localStorage.setItem('newDataSaved', true);
                Navigate(0);
            } else {
                let errorMessage = saveNewNote.data.summary === "note name already saved. Please check and try again" ?
                    saveNewNote.data.summary : "An error occurred while trying to save note. Please try again.";
                setSavingData(false);
                setErrorMessage(errorMessage);
                openNotification(errorMessage);
            }
        } catch (err) {
            console.log(err)
            setSavingData(false);
            setErrorMessage('An error occurred while trying to save note. Please try again.');
            openNotification('An error occurred while trying to save note. Please try again.');
        }
    }
    return (
        <Spin indicator={antIcon} spinning={loadingData}>
            <div>
                <div>
                    <Sidenav />
                </div>
                <div className="main-display">
                    <div>
                        <TopBar />
                    </div>
                    <div>
                        <div className="main-display-content">
                            <div>
                                <div className="grid-flex page-header">
                                    <h3>Notes</h3>
                                    <div>
                                        {
                                            displayList ?
                                                <button
                                                    onClick={() => setDisplayList(false)}
                                                    className="btn-blue">Add New Notes</button>
                                                :
                                                <button
                                                    onClick={() => setDisplayList(true)}
                                                    className="btn-blue">See All Notes</button>
                                        }
                                    </div>
                                </div>
                                <div className="">
                                    <div>
                                        <div className="content-box mb-4">
                                            {
                                                displayList ?
                                                    <React.Fragment>
                                                        <div className="content-box-header">
                                                            <div className="grid-flex">
                                                                <h3>Recently Added Notes</h3>
                                                            </div>
                                                        </div>
                                                        <div className="content-box-body">
                                                            <div>
                                                                <Table
                                                                    columns={columns}
                                                                    dataSource={data}
                                                                    bordered
                                                                    title={null}
                                                                    footer={null}
                                                                />
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <div className="content-box-header">
                                                            <div className="grid-flex">
                                                                <h3>Add New Note</h3>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="width-60">
                                                                {
                                                                    errorMessage ?
                                                                        <div className="error-block">
                                                                            <p className="error-message">{errorMessage}</p>
                                                                        </div> : ''
                                                                }
                                                                <form onSubmit={handleSubmit(saveSubject)}>
                                                                    <div className="form-group">
                                                                        <label htmlFor="noteTitle">Note title</label>
                                                                        <Controller control={control} defaultValue="" name="noteTitle"
                                                                            render={({ field }) => (
                                                                                <Input
                                                                                    style={{ height: '5rem' }} {...field}
                                                                                    name="noteTitle" id="noteTitle" type="text" />
                                                                            )} />
                                                                        {errors.noteTitle && <p className="error-message">{errors.noteTitle.message}</p>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="termTitle">Term</label>
                                                                        <Controller control={control} defaultValue="" name="termTitle"
                                                                            render={({ field }) => (
                                                                                <Select style={{ height: '5rem', width: '100%' }} {...field}
                                                                                    name="termTitle" id="termTitle">
                                                                                    {
                                                                                        termData.map((termDatum, index) => (
                                                                                            <Select.Option
                                                                                                value={termDatum.id}
                                                                                                key={index}>{termDatum.termName}</Select.Option>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            )} />
                                                                        {errors.termTitle && <p className="error-message">{errors.termTitle.message}</p>}
                                                                    </div>
                                                                    <div className="form-flex-2">
                                                                        <div className="form-group">
                                                                            <label htmlFor="studentClass">Class</label>
                                                                            <Controller control={control} defaultValue="" name="studentClass"
                                                                                render={() => (
                                                                                    <Select
                                                                                        onChange={e => getSubjectsData(e)}
                                                                                        style={{ height: '5rem', width: '100%' }}
                                                                                        name="studentClass" id="studentClass">
                                                                                        {
                                                                                            classData.map((userClass, index) => (
                                                                                                <Select.Option
                                                                                                    value={userClass.id}
                                                                                                    key={index}>{userClass.className}</Select.Option>
                                                                                            ))
                                                                                        }
                                                                                    </Select>
                                                                                )} />
                                                                            {errors.studentClass && <p className="error-message">{errors.studentClass.message}</p>}
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="studentClass">Subject</label>
                                                                            <Controller control={control} defaultValue="" name="studentClass"
                                                                                render={({ field }) => (
                                                                                    <Select style={{ height: '5rem', width: '100%' }} {...field}
                                                                                        name="studentClass" id="studentClass">
                                                                                        {
                                                                                            subjectData.map((userClass, index) => (
                                                                                                <Select.Option
                                                                                                    value={userClass.id}
                                                                                                    key={index}>{userClass.subjectName}</Select.Option>
                                                                                            ))
                                                                                        }
                                                                                    </Select>
                                                                                )} />
                                                                            {errors.studentClass && <p className="error-message">{errors.studentClass.message}</p>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="className">Notes</label>
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            data="<p>Add New Note</p>"
                                                                            onReady={editor => {
                                                                                // You can store the "editor" and use when it is needed.
                                                                                // console.log('Editor is ready to use!', editor);
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setNoteBody(data)
                                                                                // console.log({ event, editor, data });
                                                                            }}
                                                                            onBlur={(event, editor) => {
                                                                                // console.log('Blur.', editor);
                                                                            }}
                                                                            onFocus={(event, editor) => {
                                                                                // console.log('Focus.', editor);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {
                                                                        !savingData ?
                                                                            <button className="btn-blue">Save Note</button>
                                                                            :
                                                                            <button className="btn-blue" disabled><LoadingOutlined style={{ fontSize: '2rem' }} /></button>
                                                                    }
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="delete-button" title={null} open={isModalOpen} footer={null}>
                    <h3>Are you sure you want to delete this note? This action can not be reversed!</h3>
                    <div className="grid-flex">
                        <div></div>
                        <div className="grid-button">
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-default">Cancel</button>
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-red">Delete</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </Spin>
    )
}

export default Classes;