import React from "react";
import { Link, NavLink } from "react-router-dom";

import AllAppRoutes from '../utils/routes';

import { ReactComponent as Dashboard } from "../assets/images/archway.svg";
import { ReactComponent as LogOut } from "../assets/images/toggle.svg";
import { ReactComponent as Quizzes } from "../assets/images/input-device.svg";
import { ReactComponent as Notes } from "../assets/images/document.svg";
import { ReactComponent as Subjects } from "../assets/images/archive.svg";
import { ReactComponent as Teachers } from "../assets/images/setting.svg";
import { ReactComponent as Students } from "../assets/images/profile.svg";
import { ReactComponent as Classes } from "../assets/images/tv.svg";
import { ReactComponent as ExternalNavLink } from "../assets/images/external-link.svg";
// import {ReactComponent as Subjects} from "../assets/images/archive.svg";


const Sidenav = () => {
    return (
        <div className="sidenav">
            <div>
                <div className="side-title">
                    <h2>IMS SCHOOL PORTAL</h2>
                </div>
                <div>
                    <h4><Link to={AllAppRoutes.dashboard}>Admin Dashboard</Link></h4>
                </div>
                <div>
                    <ul>
                        {/* <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.dashboard}><Classes /> <span>Dashboard</span></NavLink>
                        </li> */}
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_terms}><Classes /> <span>Terms</span></NavLink>
                        </li>
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_classes}><Classes /> <span>Classes</span></NavLink>
                        </li>
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_subjects}><Subjects /><span>Subjects</span></NavLink>
                        </li>
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_students}><Students /><span>Students</span></NavLink>
                        </li>
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_teachers}><Teachers /> <span>Teachers</span></NavLink>
                        </li>
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_notes}><Notes /> <span>Notes</span></NavLink>
                        </li>
                        <li>
                            <NavLink
                                className={({ isActive }) => isActive ? 'active-link' : ''}
                                to={AllAppRoutes.all_quizzes}><Quizzes /><span>Quizzes</span></NavLink>
                        </li>
                    </ul>
                </div>
                <div className="bottom-bar">
                    <NavLink
                        className={({ isActive }) => isActive ? 'active-link' : ''}
                        to=""><ExternalNavLink /> View Site</NavLink>
                    <NavLink
                        className={({ isActive }) => isActive ? 'active-link' : ''}
                        to=""><LogOut /> Log Out</NavLink>
                </div>
            </div>
        </div>
    )
}

export default Sidenav;