import React, { useEffect, useState } from "react";

import { Input, Spin, Table, notification, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as yup from 'yup';
import { Controller, set, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { _fake_get_call, _get_teachers_data, _save_teacher_data } from "../utils/axios-routes";
import { Link, useNavigate } from "react-router-dom";
import { DateTime } from 'luxon';

import _1 from "../assets/images/_2.jpg";
import Sidenav from "../components/sidenav";
import TopBar from "../components/topbar";

const Classes = () => {

    const formValidator = yup.object().shape({
        firstName: yup.string().required('Please enter first name'),
        lastName: yup.string().required('Please enter last name'),
        emailAddress: yup.string().required('Please enter email address'),
        phoneNumber: yup.string().required('Please enter phoneNumber')
    })

    const antIcon = (<LoadingOutlined style={{ fontSize: 24 }} spin />);
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(formValidator)
    });
    const Navigate = useNavigate();

    const [displayList, setDisplayList] = useState(true);
    const [savingData, setSavingData] = useState(false);
    const [fetchingData, setFetchingData] = useState(true);
    const [teacherData, setTeacherData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    const openNotification = err => {
        notification.open({
            description: err
        });
    }

    useEffect(() => {
        if (localStorage.getItem('newDataSaved')) {
            openNotification('Data saved successfully.');
            localStorage.removeItem('newDataSaved');
        };
        let getAllTeacherData = async () => {
            try {
                let fetchTeacherData = await _get_teachers_data();
                if (fetchTeacherData.data.statusMessage === "success") {
                    setFetchingData(false);
                    setTeacherData(fetchTeacherData.data.message);
                }
                setLoadingData(false)
            } catch (err) {
                openNotification('An error occurred while fetching teacher data. Please reload to try again.');
                setLoadingData(false)
            }
        }
        getAllTeacherData();
    }, [])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email address',
            className: 'column-money',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Phone number',
            dataIndex: 'phoneNumber',
        },
        {
            title: 'Date added',
            dataIndex: 'dateAdded',
        },
        {
            title: '',
            dataIndex: 'action',
        },
    ];
    const data = teacherData.map((teachers, index) => {
        return {
            key: index,
            name: `${teachers.firstName} ${teachers.lastName}`,
            emailAddress: teachers.emailAddress,
            phoneNumber: teachers.phoneNumber,
            dateAdded: DateTime.fromISO(teachers.createdAt).toFormat('MMMM dd, yyyy'),
            action: <button onClick={() => setIsModalOpen(true)} className="btn-red">Delete</button>
        }
    });

    const saveTeacher = async e => {
        setErrorMessage('');
        const { firstName, lastName, emailAddress, phoneNumber } = e;
        let newTeacherData = {
            firstName, lastName, emailAddress, phoneNumber
        };
        setSavingData(true);
        try {
            let saveNewTeacher = await _save_teacher_data(newTeacherData);
            if (saveNewTeacher.data.statusMessage === "success") {
                setSavingData(false);
                localStorage.setItem('newDataSaved', true);
                Navigate(0);
            } else {
                let errorMessage = saveNewTeacher.data.summary === "Teacher first name and last name already added. Please check and try again" ?
                    saveNewTeacher.data.summary : "An error occurred while trying to save teacher. Please try again.";
                setSavingData(false);
                setErrorMessage(errorMessage);
                openNotification(errorMessage);
            }
        } catch (err) {
            setSavingData(false);
            setErrorMessage('An error occurred while trying to save teacher. Please try again.');
            openNotification('An error occurred while trying to save teacher. Please try again.');
        }
    }

    const deleteTeacher = async e => {

    }

    return (
        <Spin indicator={antIcon} spinning={loadingData}>
            <div>
                <div>
                    <Sidenav />
                </div>
                <div className="main-display">
                    <div>
                        <TopBar />
                    </div>
                    <div>
                        <div className="main-display-content">
                            <div>
                                <div className="grid-flex page-header">
                                    <h3>Teachers</h3>
                                    <div>
                                        {
                                            displayList ?
                                                <button
                                                    onClick={() => setDisplayList(false)}
                                                    className="btn-blue">Add New Teacher</button>
                                                :
                                                <button
                                                    onClick={() => setDisplayList(true)}
                                                    className="btn-blue">See All Teachers</button>
                                        }
                                    </div>
                                </div>
                                <div className="">
                                    <div>
                                        <div className="content-box mb-4">
                                            {
                                                displayList ?
                                                    <React.Fragment>
                                                        {
                                                            !fetchingData ?
                                                                <React.Fragment>
                                                                    <div className="content-box-header">
                                                                        <div className="grid-flex">
                                                                            <h3>Recently Added Teachers</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="content-box-body">
                                                                        <div>
                                                                            <Table
                                                                                columns={columns}
                                                                                dataSource={data}
                                                                                bordered
                                                                                title={null}
                                                                                footer={null}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                                :
                                                                <LoadingOutlined style={{ fontSize: '2rem' }} />
                                                        }
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <div className="content-box-header">
                                                            <div className="grid-flex">
                                                                <h3>Add New Teacher</h3>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="width-60">
                                                                {
                                                                    errorMessage ?
                                                                        <div className="error-block">
                                                                            <p className="error-message">{errorMessage}</p>
                                                                        </div> : ''
                                                                }
                                                                <form onSubmit={handleSubmit(saveTeacher)}>
                                                                    <div className="form-flex-2">
                                                                        <div className="form-group">
                                                                            <label htmlFor="firstName">First name</label>
                                                                            <Controller control={control} defaultValue="" name="firstName"
                                                                                render={({ field }) => (
                                                                                    <Input
                                                                                        style={{ height: '5rem' }} {...field}
                                                                                        name="firstName" id="firstName" type="text" />
                                                                                )} />
                                                                            {errors.firstName && <p className="error-message">{errors.firstName.message}</p>}
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="lastName">Last name</label>
                                                                            <Controller control={control} defaultValue="" name="lastName"
                                                                                render={({ field }) => (
                                                                                    <Input
                                                                                        style={{ height: '5rem' }} {...field}
                                                                                        name="lastName" id="lastName" type="text" />
                                                                                )} />
                                                                            {errors.lastName && <p className="error-message">{errors.lastName.message}</p>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="emailAddress">Email address</label>
                                                                        <Controller control={control} defaultValue="" name="emailAddress"
                                                                            render={({ field }) => (
                                                                                <Input
                                                                                    style={{ height: '5rem' }} {...field}
                                                                                    name="emailAddress" id="emailAddress" type="text" />
                                                                            )} />
                                                                        {errors.emailAddress && <p className="error-message">{errors.emailAddress.message}</p>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="phoneNumber">Phone number</label>
                                                                        <Controller control={control} defaultValue="" name="phoneNumber"
                                                                            render={({ field }) => (
                                                                                <Input
                                                                                    style={{ height: '5rem' }} {...field}
                                                                                    name="phoneNumber" id="phoneNumber" type="text" />
                                                                            )} />
                                                                        {errors.phoneNumber && <p className="error-message">{errors.phoneNumber.message}</p>}
                                                                    </div>
                                                                    {
                                                                        !savingData ?
                                                                            <button className="btn-blue">Save Teacher</button>
                                                                            :
                                                                            <button className="btn-blue" disabled><LoadingOutlined style={{ fontSize: '2rem' }} /></button>
                                                                    }
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="delete-button" title={null} open={isModalOpen} footer={null}>
                    <h3>Are you sure you want to delete teacher? This action can not be reversed!</h3>
                    <div className="grid-flex">
                        <div></div>
                        <div className="grid-button">
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-default">Cancel</button>
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="btn-red">Delete</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </Spin>
    )
}

export default Classes;