import React from "react";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AllAppRoutes from "./utils/routes";

import Homepage from "./pages/homepage";
import Students from "./pages/students";
import Notes from "./pages/notes";
import Quizzes from "./pages/quizzes";
import Teachers from "./pages/teachers";
import Subjects from "./pages/subjects";
import Classes from "./pages/classes";
import SignInPage from "./pages";
import Signout from "./pages/Signout";
import Terms from "./pages/terms";

const AppRoutes = () => {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<SignInPage />} />
                    <Route path="/signout" element={<Signout />} />
                    <Route path="/*" element={<Navigate to={AllAppRoutes.dashboard} />} />

                    <Route path={AllAppRoutes.dashboard} element={<Homepage />} />
                    <Route path={AllAppRoutes.all_classes} element={<Classes />} />
                    <Route path={AllAppRoutes.add_classes} element={<Students />} />
                    <Route path={AllAppRoutes.all_students} element={<Students />} />
                    <Route path={AllAppRoutes.add_students} element={<Students />} />
                    <Route path={AllAppRoutes.all_subjects} element={<Subjects />} />
                    <Route path={AllAppRoutes.add_students} element={<Subjects />} />
                    <Route path={AllAppRoutes.all_teachers} element={<Teachers />} />
                    <Route path={AllAppRoutes.add_teachers} element={<Teachers />} />
                    <Route path={AllAppRoutes.all_quizzes} element={<Quizzes />} />
                    <Route path={AllAppRoutes.add_quizzes} element={<Quizzes />} />
                    <Route path={AllAppRoutes.all_notes} element={<Notes />} />
                    <Route path={AllAppRoutes.add_notes} element={<Notes />} />
                    <Route path={AllAppRoutes.add_terms} element={<Terms />} />
                    <Route path={AllAppRoutes.all_terms} element={<Terms />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default AppRoutes;