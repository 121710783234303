import React, { useEffect, useState } from "react";

import { Divider, Spin, Table, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { _fetch_notes_data, _fetch_students_data, _fetch_quiz_data } from "../utils/axios-routes";

import _1 from "../assets/images/_1.jpg";
import Sidenav from "../components/sidenav";
import TopBar from "../components/topbar";
import { ReactComponent as ExternalNavLink } from "../assets/images/external-link.svg";
import AllAppRoutes from "../utils/routes";

const Homepage = () => {

    const antIcon = (<LoadingOutlined style={{ fontSize: 24 }} spin />);
    const openNotification = err => {
        notification.open({
            description: err
        });
    }

    const [noteData, setNoteData] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [quizData, setQuizData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        let getAllNotesData = async () => {
            try {
                let fetchNoteData = await _fetch_notes_data();
                if (fetchNoteData.data.statusMessage === "success") {
                    setNoteData(fetchNoteData.data.message);
                }
            } catch (err) {
                openNotification('An error occurred while fetching notes data. Please reload to try again.');
            }
        }
        let getAllStudentsData = async () => {
            try {
                let fetchStudentData = await _fetch_students_data();
                if (fetchStudentData.data.statusMessage === "success") {
                    setStudentData(fetchStudentData.data.message);
                }
            } catch (err) {
                openNotification('An error occurred while fetching student data. Please reload to try again.');
            }
        }
        let getAllQuizData = async () => {
            try {
                let fetchQuizData = await _fetch_quiz_data();
                if (fetchQuizData.data.statusMessage === "success") {
                    setQuizData(fetchQuizData.data.message);
                }
                setLoadingData(false);
            } catch (err) {
                setLoadingData(false);
                openNotification('An error occurred while fetching quiz data. Please reload to try again.');
            }
        }
        getAllNotesData();
        getAllStudentsData();
        getAllQuizData();
    }, [])

    const columns = [
        {
            title: 'Title',
            dataIndex: 'name',
        },
        {
            title: 'Subject',
            className: 'column-money',
            dataIndex: 'subject',
        },
        {
            title: 'Class',
            dataIndex: 'class',
        },
        {
            title: 'Created',
            dataIndex: 'dateAdded',
        }
    ];
    const data = noteData.map((note, index) => {
        return (
            {
                key: index,
                name: note.noteTitle,
                subject: note?.subject?.subjectName,
                class: note?.subject?.class?.className,
                dateAdded: DateTime.fromISO(note.createdAt).toFormat('MMMM dd, yyyy')
            }
        )
    });
    const secColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email address',
            className: 'column-money',
            dataIndex: 'emailAddress'
        },
        {
            title: 'Phone number',
            className: 'column-money',
            dataIndex: 'phoneNumber'
        },
        {
            title: 'Class',
            className: 'column-money',
            dataIndex: 'class'
        },
        {
            title: 'Address',
            dataIndex: 'address',
        },
    ];
    const secData = studentData.map((student, index) => {
        return {
            key: index,
            name: `${student.firstName} ${student.lastName}`,
            emailAddress: student.emailAddress,
            class: student?.class?.className,
            phoneNumber: student.phoneNumber,
            address: student.address,
        }
    });
    return (
        <Spin spinning={loadingData} indicator={antIcon}>
            <div className="dashboard">
                <div>
                    <Sidenav />
                </div>
                <div className="main-display">
                    <div>
                        <TopBar />
                    </div>
                    <div>
                        <div className="main-display-content">
                            <div>
                                <h3 className="dashboard-title">Admin Dashboard</h3>
                                <div className="dashboard-grid-data-2">
                                    <div>
                                        <div className="content-box overview-summary mb-4">
                                            <div className="content-box-header">
                                                <h3>Panel Overview</h3>
                                                <div className="grid-3">
                                                    <div className="overview-summary-content">
                                                        <h3>{noteData.length > 10 ? noteData.length : `0${noteData.length}`}</h3>
                                                        <p>Added Notes</p>
                                                    </div>
                                                    <div className="overview-summary-content">
                                                        <h3>{quizData.length > 10 ? quizData.length : `0${quizData.length}`}</h3>
                                                        <p>Added Quizzes</p>
                                                    </div>
                                                    <div className="overview-summary-content">
                                                        <h3>{studentData.length > 10 ? studentData.length : `0${studentData.length}`}</h3>
                                                        <p>Added Students</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="content-box mb-4">
                                            <div className="content-box-header">
                                                <div className="grid-flex">
                                                    <h3>Recently Added Notes</h3>
                                                    <Link to={AllAppRoutes.all_teachers}>See More</Link>
                                                </div>
                                            </div>
                                            <Divider style={{ marginTop: 0 }} />
                                            <div className="content-box-body">
                                                <div>
                                                    <Table
                                                        columns={columns}
                                                        dataSource={data}
                                                        bordered
                                                        title={null}
                                                        footer={null}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div className="content-box side-story mb-4">
                                                <div className="content-box-header">
                                                    <div className="grid-flex">
                                                        <h3>Welcome Home,</h3>
                                                    </div>
                                                </div>
                                                <div className="content-box-body">
                                                    <div>
                                                        <p>Hey there, this is the admin dashboard that contains summaries to
                                                            student, teacher and quiz data. From here, you can find your way to
                                                            other sides of the page.
                                                        </p>
                                                        <p className="bg-grey">This panel is open to all administrators</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="main-content-design">
                                                <div className="content-box-header">
                                                    <div className="">
                                                        <h3>The IMS Main App</h3>
                                                        <p>Hey there, this is the admin dashboard that contains summaries to
                                                            student, teacher and quiz data. From here, you can find your way to
                                                            other sides of the page.
                                                        </p>
                                                        <Link to="">Visit Page <ExternalNavLink /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-box mb-4">
                                    <div className="content-box-header">
                                        <div className="grid-flex">
                                            <h3>Recently Added Students</h3>
                                            <Link to={AllAppRoutes.all_students}>See More</Link>
                                        </div>
                                    </div>
                                    <Divider style={{ marginTop: 0 }} />
                                    <div className="content-box-body">
                                        <div>
                                            <Table
                                                columns={secColumns}
                                                dataSource={secData}
                                                bordered
                                                title={null}
                                                footer={null}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <p>Hello there my good friend</p>
                <img src={_1} alt="" /> */}
                </div>
            </div>
        </Spin>
    )
}

export default Homepage;