import axios from "axios";
import axiosCall from "./axiosCall"

export const _fake_get_call = () => {
    return setTimeout(() => { console.log('run me'); }, 4000)
}

export const _get_teachers_data = () => {
    return axiosCall.get('/teachers/get-all-teachers', {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}


export const _save_teacher_data = req => {
    return axiosCall.post('/teachers/add-new-teacher', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_class_data = req => {
    return axiosCall.get('/classes/get-all-classes', {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _save_class_data = req => {
    return axiosCall.post('/classes/add-new-class', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_subjects_data = req => {
    return axiosCall.get('/subjects/get-all-subjects', {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_single_subject_data = req => {
    return axiosCall.get(`/subjects/get-subject-by-classid/${req}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _save_subjects_data = req => {
    return axiosCall.post('/subjects/add-new-subject', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_students_data = req => {
    return axiosCall.get('/students/get-all-students', {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _save_students_data = req => {
    return axiosCall.post('/students/add-new-student', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_notes_data = req => {
    return axiosCall.get('/notes/get-all-notes', {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _save_notes_data = req => {
    return axiosCall.post('/notes/add-new-note', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_quiz_data = req => {
    return axiosCall.get('/quiz/get-all-quizzes', {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _save_quiz_data = req => {
    return axiosCall.post('/quiz/add-new-quiz', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _fetch_term_data = req => {
    return axiosCall.get('/term/get-all-terms', {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}

export const _save_term_data = req => {
    return axiosCall.post('/term/add-new-term', req, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admin-signin-token')}` }
    })
}