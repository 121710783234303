import React, { useState, useEffect } from "react";

import { Input, Spin, notification, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as yup from 'yup';
import { connect } from 'react-redux';
import { loginUser, clearLoginError } from '../utils/reducers/auth';
import { Controller, set, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { _fetch_class_data, _save_class_data } from "../utils/axios-routes";
import { Link, useNavigate } from "react-router-dom";
import axiosCall from "../utils/axiosCall";


import _1 from "../assets/images/_2.jpg";
import Sidenav from "../components/sidenav";
import TopBar from "../components/topbar";
import AllAppRoutes from "../utils/routes";

const SignInPage = props => {

    const formValidator = yup.object().shape({
        // className: yup.string().required('Please enter class name')
    })
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(formValidator)
    });

    const antIcon = (<LoadingOutlined style={{ fontSize: 24 }} spin />);
    const Navigate = useNavigate();

    const [displayList, setDisplayList] = useState(true);
    const [savingData, setSavingData] = useState(false);
    const [fetchingData, setFetchingData] = useState(true);
    const [classData, setClassData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    const openNotification = err => {
        notification.open({
            description: err
        });
    }
    useEffect(() => {
        if (props.auth.isAuthenticated) {
            window.location = AllAppRoutes.dashboard;
        }
        if (props.loginError.loginError.length) {
            props.clearLoginError();
            setErrorMessage(props.loginError.loginError);
            setLoadingData(false);
        }
    }, [props.auth, props.loginError]);

    const signInAdmin = async e => {
        setLoadingData(true);
        setErrorMessage('');
        let { emailAddress, password } = e;
        props.loginUser({
            emailAddress, password
        });
    }
    return (
        <div className="form-signin-box">
            <React.Fragment>
                <div className="auth-box">
                    <div className="content-box-header">
                        <div className="grid-flex">
                            <h3>Sign in to IMS Admin Panel</h3>
                        </div>
                    </div>
                    <div>
                        <div className="width-6">
                            {
                                errorMessage ?
                                    <div className="error-block">
                                        <p className="error-message">{errorMessage}</p>
                                    </div> : ''
                            }
                            <form onSubmit={handleSubmit(signInAdmin)}>
                                <div className="form-group">
                                    <label htmlFor="emailAddress">Email address</label>
                                    <Controller control={control} defaultValue="" name="emailAddress"
                                        render={({ field }) => (
                                            <Input
                                                style={{ height: '5rem' }} {...field}
                                                name="emailAddress" id="emailAddress" type="text" />
                                        )} />
                                    {errors.emailAddress && <p className="error-message">{errors.emailAddress.message}</p>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <Controller control={control} defaultValue="" name="password"
                                        render={({ field }) => (
                                            <Input.Password
                                                style={{ height: '5rem' }} {...field}
                                                name="password" id="password" type="text" />
                                        )} />
                                    {errors.password && <p className="error-message">{errors.password.message}</p>}
                                </div>
                                <div style={{ marginTop: 40 }}></div>
                                {
                                    !savingData ?
                                        <button className="btn-blue">Sign In</button>
                                        :
                                        <button className="btn-blue" disabled><LoadingOutlined style={{ fontSize: '2rem' }} /></button>
                                }
                            </form>
                            {/* <img src={_1} alt="" /> */}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </div>
    )
}

const mapStateToProps = state => {
    return { auth: state.auth, loginError: state.loginError }
}

export default connect(mapStateToProps, { loginUser, clearLoginError })(SignInPage);