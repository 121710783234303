import React, { useState, useEffect } from "react";

import { Input, Select, Spin, Table, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as yup from 'yup';
import { Controller, set, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { _fetch_class_data, _fetch_students_data, _save_students_data } from "../utils/axios-routes";
import { useNavigate } from "react-router-dom";


import _1 from "../assets/images/_2.jpg";
import Sidenav from "../components/sidenav";
import TopBar from "../components/topbar";

const Classes = () => {

    const formValidator = yup.object().shape({
        // className: yup.string().required('Please enter class name')
    })
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(formValidator)
    });

    const Navigate = useNavigate();
    const antIcon = (<LoadingOutlined style={{ fontSize: 24 }} spin />);

    const [displayList, setDisplayList] = useState(true);
    const [savingData, setSavingData] = useState(false);
    const [studentData, setStudentData] = useState([]);
    const [classData, setClassData] = useState([]);
    const [fetchingData, setFetchingData] = useState([]);
    const [loadingData, setLoadingData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const openNotification = err => {
        notification.open({
            description: err
        });
    }

    useEffect(() => {
        if (localStorage.getItem('newDataSaved')) {
            openNotification('Data saved successfully.');
            localStorage.removeItem('newDataSaved');
        };
        let getAllStudentsData = async () => {
            try {
                let fetchStudentData = await _fetch_students_data();
                if (fetchStudentData.data.statusMessage === "success") {
                    setFetchingData(false);
                    setStudentData(fetchStudentData.data.message);
                }
                setLoadingData(false)
            } catch (err) {
                setLoadingData(false)
            }
        }
        let getAllClassesData = async () => {
            try {
                let fetchClassData = await _fetch_class_data();
                if (fetchClassData.data.statusMessage === "success") {
                    setFetchingData(false);
                    setClassData(fetchClassData.data.message);
                }
                setLoadingData(false)
            } catch (err) {
                setLoadingData(false)
            }
        }
        getAllStudentsData();
        getAllClassesData();
    }, [])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email address',
            className: 'column-money',
            dataIndex: 'emailAddress'
        },
        {
            title: 'Phone number',
            className: 'column-money',
            dataIndex: 'phoneNumber'
        },
        {
            title: 'Class',
            className: 'column-money',
            dataIndex: 'class'
        },
        {
            title: 'Address',
            dataIndex: 'address',
        },
    ];
    const data = studentData.map((student, index) => {
        return {
            key: index,
            name: `${student.firstName} ${student.lastName}`,
            emailAddress: student.emailAddress,
            class: student?.class?.className,
            phoneNumber: student.phoneNumber,
            address: student.address,
        }
    });

    const saveStudent = async e => {
        setErrorMessage('');
        const { firstName, lastName, emailAddress, phoneNumber, address, studentClass } = e;
        let newStudentData = {
            firstName, lastName, emailAddress, phoneNumber, address, studentClass
        };
        setSavingData(true);
        try {
            let saveNewStudent = await _save_students_data(newStudentData);
            if (saveNewStudent.data.statusMessage === "success") {
                setSavingData(false);
                localStorage.setItem('newDataSaved', true);
                Navigate(0);
            } else {
                let errorMessage = saveNewStudent.data.summary === "student name already saved. Please check and try again" ?
                    saveNewStudent.data.summary : "An error occurred while trying to save student. Please try again.";
                setSavingData(false);
                setErrorMessage(errorMessage);
                openNotification(errorMessage);
            }
        } catch (err) {
            console.log(err)
            setSavingData(false);
            setErrorMessage('An error occurred while trying to save student. Please try again.');
            openNotification('An error occurred while trying to save student. Please try again.');
        }
    }
    return (
        <Spin indicator={antIcon} spinning={loadingData}>
            <div>
                <div>
                    <Sidenav />
                </div>
                <div className="main-display">
                    <div>
                        <TopBar />
                    </div>
                    <div>
                        <div className="main-display-content">
                            <div>
                                <div className="grid-flex page-header">
                                    <h3>Students</h3>
                                    <div>
                                        {
                                            displayList ?
                                                <button
                                                    onClick={() => setDisplayList(false)}
                                                    className="btn-blue">Add New Student</button>
                                                :
                                                <button
                                                    onClick={() => setDisplayList(true)}
                                                    className="btn-blue">See All Students</button>
                                        }
                                    </div>
                                </div>
                                <div className="">
                                    <div>
                                        <div className="content-box mb-4">
                                            {
                                                displayList ?
                                                    <React.Fragment>
                                                        {
                                                            !fetchingData ?
                                                                <React.Fragment>
                                                                    <div className="content-box-header">
                                                                        <div className="grid-flex">
                                                                            <h3>Recently Added Students</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="content-box-body">
                                                                        <div>
                                                                            <Table
                                                                                columns={columns}
                                                                                dataSource={data}
                                                                                bordered
                                                                                title={null}
                                                                                footer={null}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                                :
                                                                <LoadingOutlined style={{ fontSize: '2rem' }} />
                                                        }
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <div className="content-box-header">
                                                            <div className="grid-flex">
                                                                <h3>Add New Student</h3>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="width-60">
                                                                {
                                                                    errorMessage ?
                                                                        <div className="error-block">
                                                                            <p className="error-message">{errorMessage}</p>
                                                                        </div> : ''
                                                                }
                                                                <form onSubmit={handleSubmit(saveStudent)}>
                                                                    <div className="form-flex-2">
                                                                        <div className="form-group">
                                                                            <label htmlFor="lastName">Last name</label>
                                                                            <Controller control={control} defaultValue="" name="lastName"
                                                                                render={({ field }) => (
                                                                                    <Input
                                                                                        style={{ height: '5rem' }} {...field}
                                                                                        name="lastName" id="lastName" type="text" />
                                                                                )} />
                                                                            {errors.lastName && <p className="error-message">{errors.lastName.message}</p>}
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="firstName">First name</label>
                                                                            <Controller control={control} defaultValue="" name="firstName"
                                                                                render={({ field }) => (
                                                                                    <Input
                                                                                        style={{ height: '5rem' }} {...field}
                                                                                        name="firstName" id="firstName" type="text" />
                                                                                )} />
                                                                            {errors.firstName && <p className="error-message">{errors.firstName.message}</p>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="emailAddress">Email address</label>
                                                                        <Controller control={control} defaultValue="" name="emailAddress"
                                                                            render={({ field }) => (
                                                                                <Input
                                                                                    style={{ height: '5rem' }} {...field}
                                                                                    name="emailAddress" id="emailAddress" type="text" />
                                                                            )} />
                                                                        {errors.emailAddress && <p className="error-message">{errors.emailAddress.message}</p>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="phoneNumber">Phone number</label>
                                                                        <Controller control={control} defaultValue="" name="phoneNumber"
                                                                            render={({ field }) => (
                                                                                <Input
                                                                                    style={{ height: '5rem' }} {...field}
                                                                                    name="phoneNumber" id="phoneNumber" type="text" />
                                                                            )} />
                                                                        {errors.phoneNumber && <p className="error-message">{errors.phoneNumber.message}</p>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="studentClass">Class</label>
                                                                        <Controller control={control} defaultValue="" name="studentClass"
                                                                            render={({ field }) => (
                                                                                <Select style={{ height: '5rem', width: '100%' }} {...field}
                                                                                    name="studentClass" id="studentClass">
                                                                                    {
                                                                                        classData.map((userClass, index) => (
                                                                                            <Select.Option
                                                                                                value={userClass.id}
                                                                                                key={index}>{userClass.className}</Select.Option>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            )} />
                                                                        {errors.studentClass && <p className="error-message">{errors.studentClass.message}</p>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="address">Address</label>
                                                                        <Controller control={control} defaultValue="" name="address"
                                                                            render={({ field }) => (
                                                                                <Input.TextArea
                                                                                    {...field}
                                                                                    name="address" id="address" type="text" />
                                                                            )} />
                                                                        {errors.address && <p className="error-message">{errors.address.message}</p>}
                                                                    </div>
                                                                    {
                                                                        !savingData ?
                                                                            <button className="btn-blue">Save Student</button>
                                                                            :
                                                                            <button className="btn-blue" disabled><LoadingOutlined style={{ fontSize: '2rem' }} /></button>
                                                                    }
                                                                </form>
                                                                {/* <img src={_1} alt="" /> */}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Spin>
    )
}

export default Classes;